import { ReactElement, SVGProps } from 'react'
export const SvgEmail = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.333 2c0-.369.299-.667.667-.667h8c.368 0 .667.298.667.667v4.068a1.333 1.333 0 0 1 2 1.148v6.117a1.333 1.333 0 0 1-1.334 1.333H2.667a1.333 1.333 0 0 1-1.334-1.333V7.256a1.333 1.333 0 0 1 2-1.188V2Zm8 4.96V2.665H4.667v4.286l3.336 2.22a.645.645 0 0 1 .017.011l3.313-2.224Zm-7.825.823-.842-.56v6.11h10.667v-6.11l-.853.573a.67.67 0 0 1-.11.092l-3.632 2.419h-.001l-.002.002m-.002 0a1.333 1.333 0 0 1-1.466 0l-.003-.001-3.633-2.42a.667.667 0 0 1-.123-.105"
      clipRule="evenodd"
    />
  </svg>
)
