'use client'

import { FC, useState, useEffect } from 'react'

import { SimpleReview } from '@/api'
import { ROUTES } from '@/constants/routes'
import { useMediaScreen } from '@/lib/use-media-screen'
import { Container } from '@/uikit/container'
import { Button } from '@/uikit/button'
import { SvgArrow } from '@/uikit/svg'
import { ReviewCard } from '@/components/review-card'

import styles from './user-reviews.module.scss'

interface UserReviewsProps {
  reviews: SimpleReview[]
}

export const UserReviews: FC<UserReviewsProps> = ({ reviews }) => {
  const [list, setList] = useState(reviews)
  const screen = useMediaScreen()

  useEffect(() => {
    if (screen === 'medium' || screen === 'smedium') {
      setList(reviews.slice(0, 2))
    }
  }, [reviews, screen])

  return (
    <Container>
      <div className={styles.root}>
        <h2>Oтзывы</h2>
        <div className={styles.list}>
          {list.slice(0, 3).map((review) => (
            <div key={review.id}>
              <ReviewCard review={review} />
            </div>
          ))}
        </div>
        <div className={styles.btn}>
          <Button
            variant="black-link"
            href={`/${ROUTES.REVIEW}`}
            target="_blank"
            endIcon={<SvgArrow />}
          >
            Смотреть все отзывы
          </Button>
        </div>
      </div>
    </Container>
  )
}
