'use client'

import { withStyles } from '@bruitt/classnames'
import { FC, useState } from 'react'
import { useRouter } from 'next/navigation'

import { Content } from '@/api'
import { formatCurrency } from '@/lib/number'
import { groupingElementByTag } from '@/lib/grouping-element-by-tag'
import { ROUTES } from '@/constants/routes'
import { Tabs } from '@/uikit/tabs'
import { Button } from '@/uikit/button'
import { Container } from '@/uikit/container'
import { SvgArrow } from '@/uikit/svg'
import { Modal } from '@/uikit/modal'
import { SimpleServiceRequest } from '@/components/simple-service-request'

import styles from './types-job.module.scss'

interface TypesJobProps {
  content?: Content
}

const sx = withStyles(styles)

export const TypesJob: FC<TypesJobProps> = ({ content }) => {
  const router = useRouter()
  const [isOpen, setIsOpen] = useState(false)
  const [service, setService] = useState<{
    name?: string | null
    slug?: string | null
    id?: number | null
  }>()

  if (!content) return null

  const { otherData, cards } = content

  if (!cards) return null

  if (!otherData) return null

  const { header, subtitle } = otherData

  const tabs = groupingElementByTag(cards || [])

  return (
    <Container>
      <div className={styles.root}>
        {header && <h2 className={styles.title}>{header}</h2>}
        {subtitle && (
          <div className={sx('subtitle', { subtitle_padding: tabs.length === 1 })}>
            {subtitle}
          </div>
        )}
        {!!tabs.length && (
          <Tabs>
            {tabs.length > 1 && (
              <Tabs.Title>
                {tabs.map(({ tag }) => (
                  <div key={tag}>{tag}</div>
                ))}
              </Tabs.Title>
            )}
            <Tabs.Grid>
              {tabs.map((tab) => (
                <div key={tab + 'panel'} className={styles.panel_list}>
                  {tab.list.map(({ id, header, description, otherData }) => (
                    <div
                      key={id}
                      className={styles.panel}
                      onClick={() => router.push(`/${ROUTES.SERVICES}/${otherData?.serviceSlug}`)}
                    >
                      <div>
                        <div className={styles.panel_title}>{header}</div>
                        <div className={styles.panel_subtitle}>{description}</div>
                      </div>
                      {otherData && (
                        <div>
                          {otherData.price && otherData.measureUnit && (
                            <div className={styles.panel_price}>
                              от {formatCurrency(otherData.price)} ₽/{otherData.measureUnit}
                            </div>
                          )}
                          <div className={styles.btn_group}>
                            <Button
                              className={styles.btn}
                              onClick={(event) => {
                                event.preventDefault()
                                event.stopPropagation()
                                setService({
                                  id: otherData.serviceId,
                                  name: header,
                                  slug: otherData.serviceSlug,
                                })
                                setIsOpen(true)
                              }}
                            >
                              Рассчитать стоимость
                            </Button>
                            <Button
                              className={styles.btn_work}
                              variant="black-link"
                              href={`/${ROUTES.SERVICES}/${otherData.serviceSlug}`}
                              endIcon={<SvgArrow />}
                            >
                              Подробнее
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </Tabs.Grid>
          </Tabs>
        )}
      </div>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <SimpleServiceRequest
          position="bottom"
          header="Отправить заявку на консультацию"
          description="<p>Предоставим полную смету на весь ремонт в день выезда замерщика.</p><br /><p>Смета включает стоимость выполнения всех работ и перечень всех необходимых материалов.</p>"
          btnText="Рассчитать стоимость"
          variant="modal"
          service={service}
          reachGoalName="form-jop-type"
        />
      </Modal>
    </Container>
  )
}
