import { FC } from 'react'

import { Container } from '@/uikit/container'

import styles from './title.module.scss'

interface TitleProps {
  title?: string | null
  subtitle?: string | null
}

export const Title: FC<TitleProps> = ({ title, subtitle }) => {
  return (
    <div className={styles.root}>
      <Container>
        <h2>{title}</h2>
        <div className={styles.subtitle}>{subtitle}</div>
      </Container>
    </div>
  )
}
