import { ContentCard, PortfolioWork, PortfolioTag } from '@/api'

type ArrayElement = ContentCard[]
interface List {
  tag?: string | null
  list: ArrayElement
}

export const groupingElementByTag = (list: ArrayElement) => {
  if (!list) return []

  return list.reduce((acc: List[], value) => {
    const index = acc.findIndex(({ tag }) => {
      if ((!tag && value?.otherData?.tag === '') || (tag === '' && !value?.otherData?.tag)) {
        return true
      }

      return tag === value?.otherData?.tag
    })

    if (index > -1) {
      acc[index].list.push(value)

      return acc
    }

    let element = {
      tag: value?.otherData?.tag,
      list: [value],
    }

    acc.push(element)

    return acc
  }, [])
}

interface PortfolioList {
  tag?: PortfolioTag | null
  list: PortfolioWork[]
}

export const groupingGalleryByTag = (list: PortfolioWork[]) => {
  if (!list) return []

  return list.reduce((acc: PortfolioList[], value) => {
    const index = acc.findIndex(({ tag }) => {
      if (!tag?.id) {
        return true
      }

      return tag?.id === value.tag?.id
    })

    if (index > -1) {
      acc[index].list?.push(value)

      return acc
    }

    let element = {
      tag: value?.tag,
      list: [value],
    }

    acc.push(element)

    return acc
  }, [])
}
