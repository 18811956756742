import { FC, ReactNode } from 'react'

import { ROUTES } from '@/constants/routes'
import { Button } from '@/uikit/button'
import { SvgArrow } from '@/uikit/svg'

import styles from './description.module.scss'

interface DescriptionProps {
  children: ReactNode
  name?: string
  slug?: string | null
}

export const Description: FC<DescriptionProps> = ({ children, name, slug }) => {
  return (
    <div className={styles.root}>
      {children}
      <div className={styles.bottom}>
        {name && slug && (
          <Button
            variant="black-link"
            href={`/${ROUTES.LOCATIONS}/${slug}`}
            endIcon={<SvgArrow />}
          >
            {name} по регионам
          </Button>
        )}
      </div>
    </div>
  )
}
