import { ReactElement, SVGProps } from 'react'
export const SvgPlus = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <g fill="currentColor" clipPath="url(#plus_svg__a)">
      <path d="M23.421 11.415a.828.828 0 0 1-.586 1.414H1.18a.829.829 0 1 1 0-1.657h21.655a.826.826 0 0 1 .586.243Z" />
      <path d="M12.594.586a.826.826 0 0 1 .242.586v21.655a.829.829 0 0 1-1.657 0V1.172a.83.83 0 0 1 1.415-.586Z" />
    </g>
    <defs>
      <clipPath id="plus_svg__a">
        <path fill="currentColor" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
)
