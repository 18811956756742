'use client'

import { withStyles } from '@bruitt/classnames'
import { FC, ReactNode, useState, Fragment, Children } from 'react'
import { Tab } from '@headlessui/react'

import { ButtonTab } from '@/uikit/button-tab'

import styles from './tabs.module.scss'

export interface TabsProps {
  children?: ReactNode
}

const sx = withStyles(styles)

const Tabs: FC<TabsProps> = ({ children }) => {
  const [selectedIndex, setSelectedIndex] = useState(0)

  return (
    <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
      {children}
    </Tab.Group>
  )
}

const TabsTitle: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Tab.List className={sx('list')}>
      <div className={styles.row}>
        {Children.map(children, (child, index) => (
          <Tab key={index} as={Fragment}>
            {({ selected }) => <ButtonTab isActive={selected}>{child}</ButtonTab>}
          </Tab>
        ))}
      </div>
    </Tab.List>
  )
}

TabsTitle.displayName = 'TabsTitle'

const TabsGrid: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Tab.Panels className={styles.panels}>
      {Children.map(children, (child, index) => (
        <Tab.Panel key={index}>{child}</Tab.Panel>
      ))}
    </Tab.Panels>
  )
}

TabsGrid.displayName = 'TabsGrid'

const TabsNamespace = Object.assign(Tabs, {
  Title: TabsTitle,
  Grid: TabsGrid,
})

export { TabsNamespace as Tabs }
