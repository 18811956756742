import { FC } from 'react'

import { FAQ } from '@/api'
import { Container } from '@/uikit/container'
import { Collapse } from '@/uikit/collapse'

import styles from './faq.module.scss'

interface FaqProps {
  faq: FAQ[]
}

export const Faq: FC<FaqProps> = ({ faq }) => {
  if (!faq.length) return null

  const length = faq.length
  const firstColumnLength = Math.ceil(length / 2)

  return (
    <div className={styles.faq} itemScope itemType="https://schema.org/FAQPage">
      <Container>
        <h2 className={styles.title}>Часто задаваемые вопросы</h2>
        <div className={styles.block}>
          <>
            {faq.slice(0, firstColumnLength).map(({ id, answer, question }) => {
              return (
                <div
                  key={id}
                  className={styles.list_item}
                  itemScope
                  itemProp="mainEntity"
                  itemType="https://schema.org/Question"
                >
                  <Collapse title={question} description={answer} />
                </div>
              )
            })}
          </>
          <>
            {faq.slice(firstColumnLength, length).map(({ id, answer, question }) => {
              return (
                <div
                  key={id}
                  className={styles.list_item}
                  itemScope
                  itemProp="mainEntity"
                  itemType="https://schema.org/Question"
                >
                  <Collapse title={question} description={answer} />
                </div>
              )
            })}
          </>
        </div>
      </Container>
    </div>
  )
}
