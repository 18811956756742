// Пришлось подключить иконки не через Image nextjs потому что у них может быть разная ширина и она динамическая
/* eslint-disable @next/next/no-img-element */
import { FC } from 'react'

import { Content } from '@/api'
import { pluralizeCardinal } from '@/lib/i18n'
import { TEXT_VARIABLES } from '@/constants/constants'
import { Button } from '@/uikit/button'
import { SvgStar, SvgArrow } from '@/uikit/svg'
import { Container } from '@/uikit/container'

import styles from './reviews.module.scss'

interface ReviewsProps {
  content?: Content
}

const { REVIEWS } = TEXT_VARIABLES

export const Reviews: FC<ReviewsProps> = ({ content }) => {
  if (!content) return null

  const { otherData, cards } = content

  return (
    <Container>
      <div className={styles.root}>
        <h2>{otherData?.header}</h2>
        <div className={styles.subtitle}>{otherData?.subtitle}</div>
        <div className={styles.list}>
          {!!cards?.length &&
            cards.map(({ id, iconImg, otherData, header, text }) => (
              <div key={id} className={styles.item}>
                <div className={styles.card}>
                  <div className={styles.top}>
                    {iconImg && (
                      <div className={styles.icon}>
                        <img src={iconImg} alt={header || 'Социальные сети'} />
                      </div>
                    )}
                    {otherData && (
                      <div className={styles.star}>
                        <div className={styles.icon_star}>
                          <SvgStar />
                        </div>
                        {otherData.stars}
                      </div>
                    )}
                  </div>
                  {otherData && otherData.link && (
                    <>
                      {otherData.reviewsAmount && (
                        <div className={styles.review}>
                          {pluralizeCardinal(otherData.reviewsAmount, [...REVIEWS])}
                        </div>
                      )}
                      <Button
                        variant="black-link"
                        className={styles.btn}
                        href={otherData.link}
                        target="_blank"
                        endIcon={<SvgArrow />}
                      >
                        {otherData.name}
                      </Button>
                    </>
                  )}
                </div>
                <div className={styles.card_title}>{header}</div>
                <div className={styles.card_subtitle}>{text}</div>
              </div>
            ))}
        </div>
      </div>
    </Container>
  )
}
