import { FC } from 'react'

import { Content } from '@/api'
import { ROUTES } from '@/constants/routes'
import { groupingGalleryByTag } from '@/lib/grouping-element-by-tag'
import { Container } from '@/uikit/container'
import { Tabs } from '@/uikit/tabs'
import { Button } from '@/uikit/button'
import { SvgArrow } from '@/uikit/svg'
import { ElementsPortfolio } from '@/components/elements-portfolio'

import styles from './gallery.module.scss'

interface GalleryProps {
  content?: Content
}

export const Gallery: FC<GalleryProps> = ({ content }) => {
  if (!content) return null

  const { otherData } = content

  if (!otherData) return null

  const { header, subtitle, text, portfolios } = otherData

  const tabs = groupingGalleryByTag(portfolios || []).sort(function (a, b) {
    if (!a.tag?.id || !b.tag?.id) return 0

    if (a.tag.id > b.tag.id) {
      return 1
    }
    if (a.tag.id < b.tag.id) {
      return -1
    }

    return 0
  })

  return (
    <Container>
      <div className={styles.root}>
        <h2 className={styles.title}>{header}</h2>
        <div className={styles.subtitle}>{subtitle}</div>
        <div>
          {!!tabs.length && (
            <Tabs>
              {tabs.length > 1 && (
                <Tabs.Title>
                  {tabs.map(({ tag }) => (
                    <div key={tag?.id}>{tag?.name}</div>
                  ))}
                </Tabs.Title>
              )}
              <Tabs.Grid>
                {tabs.map((tab) => (
                  <div key={tab + 'panel'}>
                    <ElementsPortfolio list={tab.list} />
                  </div>
                ))}
              </Tabs.Grid>
            </Tabs>
          )}
        </div>
        {!!tabs.length && (
          <div className={styles.btn}>
            <Button
              variant="black-link"
              href={`/${ROUTES.PORTFOLIO}`}
              target="_blank"
              endIcon={<SvgArrow />}
            >
              Перейти в портфолио
            </Button>
          </div>
        )}
        {text && <div className={styles.text} dangerouslySetInnerHTML={{ __html: text }} />}
      </div>
    </Container>
  )
}
