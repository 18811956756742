'use client'

import { FC } from 'react'

import { Content } from '@/api'
import { VideoPlayer } from '@/uikit/video-player'
import { Button } from '@/uikit/button'
import { Container } from '@/uikit/container'

import styles from './video-block.module.scss'

interface VideoBlockProps {
  content?: Content
}

export const VideoBlock: FC<VideoBlockProps> = ({ content }) => {
  if (!content) return null

  const { otherData } = content

  if (!otherData) return null

  const { header, text, channelLink, link } = otherData

  return (
    <Container>
      <div className={styles.root}>
        <h2>{content.header}</h2>
        <div className={styles.content}>
          {link && (
            <div className={styles.video}>
              <VideoPlayer videoId={link} title={header ?? ''} />
            </div>
          )}
          <div className={styles.text_block}>
            <div className={styles.subtitle}>{header}</div>
            <div className={styles.text}>{text}</div>
            {channelLink && (
              <Button variant="green-link" href={channelLink} target="_blank">
                Перейти на Youtube канал
              </Button>
            )}
          </div>
        </div>
      </div>
    </Container>
  )
}
