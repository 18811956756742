'use client'

import { FC, useState } from 'react'

import { Content } from '@/api'
import { formatCurrency } from '@/lib/number'
import { WIDGETS } from '@/constants/widgets'
import { ROUTES } from '@/constants/routes'
import { Button } from '@uikit/button'
import { Container } from '@/uikit/container'
import { SvgArrow } from '@/uikit/svg'
import { Modal } from '@/uikit/modal'
import { Gallery } from '../gallery'
import { BackgroundContainer } from '../background-container'
import { List } from '../list'
import { Title } from '../title'
import { SimpleServiceRequest } from '../simple-service-request'

import styles from './template3.module.scss'

interface Template3Props {
  service: {
    id: number
    name?: string
    header?: string
    price?: number | null
    measureUnit?: string | null
    description?: string | null
    slug?: string | null
    content?: Content[] | null
  }
}

export const Template3: FC<Template3Props> = ({ service }) => {
  const [isOpen, setIsOpen] = useState(false)

  const { id, name, header, price, measureUnit, description, slug, content } = service

  const blockBeforeFooter = content?.find((content: Content) => content.slug === WIDGETS.FOOTER)

  return (
    <>
      <Container>
        <div className={styles.root}>
          <div className={styles.top_section}>
            <div>
              <h1 className={styles.title}>{header}</h1>
              {price && (
                <div className={styles.price}>
                  от {formatCurrency(price)} ₽/{measureUnit}
                </div>
              )}
            </div>
            <div>
              <Button onClick={() => setIsOpen(true)}>Рассчитать стоимость</Button>
            </div>
          </div>
          {description && (
            <>
              <div className={styles.text} dangerouslySetInnerHTML={{ __html: description }} />
              <div className={styles.bottom}>
                {name && slug && (
                  <Button
                    variant="black-link"
                    href={`/${ROUTES.LOCATIONS}/${slug}`}
                    endIcon={<SvgArrow />}
                  >
                    {name} по регионам
                  </Button>
                )}
              </div>
            </>
          )}
          <Gallery
            content={content?.find((content: Content) => content.slug === WIDGETS.PORTFOLIO)}
          />
        </div>
      </Container>
      <Title
        title={blockBeforeFooter?.otherData?.header}
        subtitle={blockBeforeFooter?.otherData?.subtitle}
      />
      <BackgroundContainer background="/images/moks/plan.png" title={header ?? ''} size="large">
        <List template={3} content={blockBeforeFooter}>
          <SimpleServiceRequest
            position="bottom"
            header="Отправить заявку на консультацию"
            btnText="Отправить"
            service={{
              name,
              slug,
              id,
            }}
            variant="banner"
            reachGoalName="form-footer"
          />
        </List>
      </BackgroundContainer>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <SimpleServiceRequest
          position="bottom"
          header="Отправить заявку на консультацию"
          description="<p>Предоставим полную смету на весь ремонт в день выезда замерщика.</p><br /><p>Смета включает стоимость выполнения всех работ и перечень всех необходимых материалов.</p>"
          btnText="Рассчитать стоимость"
          variant="modal"
          service={service}
          reachGoalName="form-btn"
        />
      </Modal>
    </>
  )
}
