import { withStyles } from '@bruitt/classnames'
import { FC, ReactNode } from 'react'
import Image from 'next/image'

import { Content } from '@/api'
import { Container } from '@/uikit/container'

import styles from './list.module.scss'

const sx = withStyles(styles)

interface ListProps {
  children: ReactNode
  template: 1 | 2 | 3
  content?: Content
}

export const List: FC<ListProps> = (props) => {
  const { children, template, content } = props

  if (!content?.cards) return null

  const { cards } = content

  return (
    <Container>
      <div className={sx('root', { template })}>
        <div className={styles.content}>
          {/* <div className={styles.title}>{DATA.header}</div> */}
          <div className={styles.list}>
            {!!cards?.length &&
              cards?.map(({ header, text, iconImg }, index) => (
                <div key={header} className={styles.list_item}>
                  <div className={styles.item_icon}>
                    {iconImg && header && (
                      <Image
                        src={iconImg}
                        alt={header}
                        fill
                        object-fit="fill"
                        sizes="(max-width: 768px) 56px, 40px"
                      />
                    )}
                  </div>
                  <div>
                    <div className={styles.item_title}>{header}</div>
                    <div className={styles.item_text}>{text}</div>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className={styles.form}>{children}</div>
      </div>
    </Container>
  )
}
