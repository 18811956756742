export { SvgArrow } from './arrow'
export { SvgClose } from './close'
export { SvgEmail } from './email'
export { SvgLaptop } from './laptop'
export { SvgMap } from './map'
export { SvgMenu } from './menu'
export { SvgMiniLogo } from './mini-logo'
export { SvgNextArrow } from './next-arrow'
export { SvgNextPagination } from './next-pagination'
export { SvgPhone } from './phone'
export { SvgPlus } from './plus'
export { SvgPreloader } from './preloader'
export { SvgSearch } from './search'
export { SvgStar } from './star'
