import { FC, ReactNode } from 'react'
import Image from 'next/image'
import { withStyles } from '@bruitt/classnames'

import styles from './background-container.module.scss'

const sx = withStyles(styles)

interface BackgroundContainerProps {
  children: ReactNode
  background: string
  title: string
  size?: 'small' | 'medium' | 'large'
}

export const BackgroundContainer: FC<BackgroundContainerProps> = ({
  children,
  background,
  title,
  size = 'medium',
}) => {
  return (
    <div className={sx('root', { size })}>
      <div className={styles.background_image}>
        <Image
          src={background}
          alt={title}
          fill
          // object-fit="cover"
          style={{ objectFit: 'cover', objectPosition: 'right center' }}
          // sizes="(max-width: 768px) 456px, 100vw"
        />
        <div className={styles.background} />
      </div>
      {children}
    </div>
  )
}
