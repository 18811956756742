import { FC } from 'react'
import LiteYouTubeEmbed from 'react-lite-youtube-embed'

interface VideoPlayerProps {
  videoId: string
  title: string
}

export const VideoPlayer: FC<VideoPlayerProps> = ({ videoId, title }) => {
  return (
    <LiteYouTubeEmbed
      id={videoId}
      title={title}
      poster="maxresdefault"
      playerClass="lty-playbtn"
      wrapperClass="yt-lite"
    />
  )
}
