'use client'
import { FC } from 'react'

import { Content, ServiceDetailUser } from '@/api'
import { WIDGETS } from '@/constants/widgets'
import { Section } from '@/uikit/section'
import { BackgroundContainer } from '../background-container'
import { Banner } from '../banner'
import { Description } from '../description'
import { Faq } from '../faq'
import { FormWithText } from '../form-with-text'
import { HowWeWork } from '../how-we-work'
import { List } from '../list'
import { Reviews } from '../reviews'
import { Title } from '../title'
import { TypesJob } from '../types-job'
import { VideoBlock } from '../video-block'
import { UserReviews } from '../user-reviews'
import { Gallery } from '../gallery'
import { Template3 } from '../template3'
import { Calculator } from '../calculator'
import { SimpleServiceRequest } from '../simple-service-request'

interface TemplateProps {
  service: ServiceDetailUser
}

export const Template: FC<TemplateProps> = ({ service }) => {
  const {
    id,
    name,
    currentTemplate,
    seoData,
    content,
    faq,
    price,
    measureUnit,
    slug,
    calculator,
    pageType,
    review,
  } = service
  const header = seoData?.headerMain ?? ''
  const description = seoData?.descriptionMain
  const banner = content?.find((content: Content) => content.slug === WIDGETS.BANNER)
  const blockBeforeFooter = content?.find((content: Content) => content.slug === WIDGETS.FOOTER)

  return (
    <>
      {currentTemplate === 1 && (
        <>
          {banner?.iconImg && (
            <BackgroundContainer background={banner.iconImg} title={header}>
              <Banner
                service={{ measureUnit, price, header, id, name, slug }}
                content={banner}
                template={currentTemplate}
                pageType={pageType}
              >
                <Calculator
                  data={calculator?.structure ? JSON.parse(calculator?.structure) : []}
                  position="top"
                  template={currentTemplate}
                  service={{
                    id,
                    name,
                    slug,
                  }}
                  reachGoalName="calc1"
                />
              </Banner>
            </BackgroundContainer>
          )}
          <TypesJob
            content={content?.find((content: Content) => content.slug === WIDGETS.WORK_TYPE)}
          />
          <Reviews
            content={content?.find((content: Content) => content.slug === WIDGETS.REWIES)}
          />
          <HowWeWork
            content={content?.find((content: Content) => content.slug === WIDGETS.WORK)}
          />
          <FormWithText
            content={content?.find((content: Content) => content.slug === WIDGETS.FORM_WITH_TEXT)}
            service={{
              id,
              name,
              slug,
            }}
          />
          <Gallery
            content={content?.find((content: Content) => content.slug === WIDGETS.PORTFOLIO)}
          />
          {!!review?.length && <UserReviews reviews={review} />}
          {!!faq?.length && <Faq faq={faq} />}
          <VideoBlock
            content={content?.find((content: Content) => content.slug === WIDGETS.VIDEO)}
          />
          {description && (
            <Section isHavePadding>
              <Description name={name} slug={slug}>
                <div dangerouslySetInnerHTML={{ __html: description }} />
              </Description>
            </Section>
          )}
          <Title
            title={blockBeforeFooter?.otherData?.header}
            subtitle={blockBeforeFooter?.otherData?.subtitle}
          />
          <BackgroundContainer background="/images/moks/plan.png" title={header} size="large">
            <List template={currentTemplate} content={blockBeforeFooter}>
              <Calculator
                data={calculator?.structure ? JSON.parse(calculator?.structure) : []}
                position="bottom"
                template={currentTemplate}
                service={{
                  name,
                  slug,
                  id,
                }}
                reachGoalName="calc2"
              />
            </List>
          </BackgroundContainer>
        </>
      )}
      {currentTemplate === 2 && (
        <>
          {banner?.iconImg && (
            <BackgroundContainer background={banner.iconImg} title={header} size="small">
              <Banner
                service={{ measureUnit, price, header, id, name, slug }}
                content={banner}
                template={currentTemplate}
              >
                <SimpleServiceRequest
                  position="top"
                  header="Отправить заявку на консультацию"
                  btnText="Рассчитать стоимость"
                  service={{
                    name,
                    slug,
                    id,
                  }}
                  variant="banner"
                  reachGoalName="form-banner"
                />
              </Banner>
            </BackgroundContainer>
          )}
          <TypesJob
            content={content?.find((content: Content) => content.slug === WIDGETS.WORK_TYPE)}
          />
          <Reviews
            content={content?.find((content: Content) => content.slug === WIDGETS.REWIES)}
          />
          <HowWeWork
            content={content?.find((content: Content) => content.slug === WIDGETS.WORK)}
          />
          <FormWithText
            content={content?.find((content: Content) => content.slug === WIDGETS.FORM_WITH_TEXT)}
            service={{
              id,
              name,
              slug,
            }}
          />
          <Gallery
            content={content?.find((content: Content) => content.slug === WIDGETS.PORTFOLIO)}
          />
          {!!review?.length && <UserReviews reviews={review} />}
          {!!faq?.length && <Faq faq={faq} />}
          <VideoBlock
            content={content?.find((content: Content) => content.slug === WIDGETS.VIDEO)}
          />
          {description && (
            <Section isHavePadding>
              <Description name={name} slug={slug}>
                <div dangerouslySetInnerHTML={{ __html: description }} />
              </Description>
            </Section>
          )}
          <Title
            title={blockBeforeFooter?.otherData?.header}
            subtitle={blockBeforeFooter?.otherData?.subtitle}
          />
          <BackgroundContainer background="/images/moks/plan.png" title={header} size="large">
            <List template={currentTemplate} content={blockBeforeFooter}>
              <Calculator
                data={calculator?.structure ? JSON.parse(calculator?.structure) : []}
                position="bottom"
                template={currentTemplate}
                service={{
                  id,
                  name,
                  slug,
                }}
                reachGoalName="calc3"
              />
            </List>
          </BackgroundContainer>
        </>
      )}
      {currentTemplate === 3 && (
        <Template3
          service={{
            id,
            header,
            name,
            price,
            measureUnit,
            description,
            slug,
            content,
          }}
        />
      )}
    </>
  )
}
