import { FC } from 'react'

import { PortfolioWork } from '@/api'
import { ElementPortfolio } from '@/components/element-portfolio'

import styles from './elements-portfolio.module.scss'

interface ElementsPortfolioProps {
  list: PortfolioWork[]
}

export const ElementsPortfolio: FC<ElementsPortfolioProps> = ({ list }) => {
  return (
    <div className={styles.root}>
      {list.map((item) => (
        <ElementPortfolio key={item.id} element={item} />
      ))}
    </div>
  )
}
