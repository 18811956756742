import { FC, useState, useEffect, useRef } from 'react'
import { withStyles } from '@bruitt/classnames'
import { useToggle } from 'react-use'

import { SimpleReview, Review } from '@/api'
import { Button } from '@/uikit/button'
import { SvgMiniLogo, SvgStar, SvgNextArrow } from '@/uikit/svg'

import styles from './review-card.module.scss'
import { ROUTES } from '@/constants/routes'

interface ReviewCardProps {
  review: SimpleReview | Review
}

const sx = withStyles(styles)
const STARS_NUBER = [1, 2, 3, 4, 5]
const MOTHS = [
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря',
]

export const ReviewCard: FC<ReviewCardProps> = ({ review }) => {
  const refText = useRef<HTMLDivElement>(null)
  const [maxHeight, setMaxHeight] = useState<number>(96)
  const [toggleText, toggleOpenText] = useToggle(false)

  useEffect(() => {
    setMaxHeight(refText.current?.scrollHeight ?? 0)
  }, [])

  return (
    <div className={styles.card}>
      <div>
        <div className={styles.row}>
          <div className={styles.name}>{review.author}</div>
          <div className={styles.logo}>
            <SvgMiniLogo />
          </div>
        </div>
        <div className={styles.row}>
          {review.publishedAt && (
            <div className={styles.date}>
              {new Date(review.publishedAt).getUTCDate()}{' '}
              {MOTHS[new Date(review.publishedAt).getMonth()]}{' '}
              {new Date(review.publishedAt).getFullYear()}
            </div>
          )}

          <div className={styles.stars}>
            {STARS_NUBER.map((star, index) => (
              <div key={star} className={sx('star', { active: (review.rating ?? 0) > index })}>
                <SvgStar />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        className={styles.text}
        ref={refText}
        style={{ maxHeight: toggleText ? maxHeight : 96 }}
      >
        <div ref={refText}>
          {review.text}
          {(review as Review).service && (
            <div className={styles.service_btn}>
              <Button
                target="_blank"
                variant="green-link"
                href={`/${ROUTES.SERVICES}/${(review as Review).service.slug}`}
              >
                Услуга {(review as Review).service.name}
              </Button>
            </div>
          )}
        </div>
      </div>
      {maxHeight > 96 && (
        <div className={styles.btn_more}>
          <Button variant="gray-link" onClick={() => toggleOpenText(!toggleText)}>
            <div>Подробнее</div>
            <div className={sx('icon', { open: toggleText })}>
              <SvgNextArrow />
            </div>
          </Button>
        </div>
      )}
    </div>
  )
}
