'use client'

import { FC } from 'react'
import Image from 'next/image'

import { Content } from '@/api'
import { ROUTES } from '@/constants/routes'
import { DOCUMENT_LINK_POLITIKA, DOCUMENT_LINK_SOGLASIE } from '@/constants/constants'
import { Container } from '@/uikit/container'
import { Button } from '@/uikit/button'
import { SimpleServiceRequest } from '../simple-service-request'

import styles from './form-with-text.module.scss'

interface FormWithTextProps {
  content?: Content
  service: {
    name?: string | null
    slug?: string | null
    id: number
  }
}

export const FormWithText: FC<FormWithTextProps> = ({ content, service }) => {
  if (!content) return null

  const { otherData, iconImg } = content

  if (!otherData) return null

  const { header, subtitle, formTitle, formSubtitle } = otherData
  const { id, name, slug } = service

  return (
    <Container>
      <div className={styles.root}>
        <h3 className={styles.title}>{header}</h3>
        <div className={styles.subtitle}>
          <div className={styles.subtitle__main}>{subtitle}</div>
        </div>
        <div className={styles.content}>
          <div className={styles.container}>
            <div className={styles.form_container}>
              <div>
                <h3 className={styles.form_title}>{formTitle}</h3>
                {formSubtitle && (
                  <div
                    className={styles.subtitle}
                    dangerouslySetInnerHTML={{ __html: formSubtitle }}
                  />
                )}
              </div>
              <div className={styles.form}>
                <SimpleServiceRequest
                  btnText="Отправить"
                  service={{
                    id,
                    name,
                    slug,
                  }}
                  reachGoalName="centr-form"
                />
              </div>
            </div>
            <div className={styles.links}>
              Я принимаю условия{' '}
              <Button
                variant="green-link"
                href={`/${ROUTES.DOCUMENTS}/${DOCUMENT_LINK_POLITIKA}`}
                target="_blank"
              >
                Политики обработки и защиты персональных данных
              </Button>
              , даю{' '}
              <Button
                variant="green-link"
                href={`/${ROUTES.DOCUMENTS}/${DOCUMENT_LINK_SOGLASIE}`}
                target="_blank"
              >
                согласие
              </Button>{' '}
              на обработку персональных данных.
            </div>
          </div>
          {iconImg && header && (
            <div className={styles.icon}>
              <Image
                src={iconImg}
                alt={header}
                fill
                object-fit="fill"
                sizes="(max-width: 768px) 456px, 100vw"
              />
            </div>
          )}
        </div>
      </div>
    </Container>
  )
}
