export const WIDGETS = {
  TEXT: 'text',
  BANNER: 'banner',
  ADVANTAGE: 'advantage',
  COMPANY_TEXT: 'company_text',
  WORK: 'work',
  CONTACTS: 'contacts',
  CUSTOM_ADVANTAGE: 'custom_advantage',
  REWIES: 'platform_reviews',
  VIDEO: 'video',
  FORM_WITH_TEXT: 'form_with_text',
  WORK_TYPE: 'work_type',
  PORTFOLIO: 'portfolio',
  FOOTER: 'footer',
}
