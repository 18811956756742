import { ReactElement, SVGProps } from 'react'
export const SvgNextPagination = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 8 8" {...props}>
    <g clipPath="url(#next-pagination_svg__a)">
      <path
        fill="currentColor"
        d="M2.275 3.48a.706.706 0 0 0 0 1.038L4.766 6.82a.706.706 0 0 0 1.186-.52V1.699a.706.706 0 0 0-1.186-.519l-2.49 2.3Z"
      />
    </g>
    <defs>
      <clipPath id="next-pagination_svg__a">
        <path fill="currentColor" d="M8 0v8H0V0z" />
      </clipPath>
    </defs>
  </svg>
)
