import { FC, useState } from 'react'

import { PortfolioWork } from '@/api'
import { Slider } from '@/uikit/slider'
import { getPortfolioGalleryList } from '@/modules/portfolio'

import styles from './element-portfolio.module.scss'

interface ElementPortfolioProps {
  element: PortfolioWork
}

export const ElementPortfolio: FC<ElementPortfolioProps> = ({ element }) => {
  const [gallery, setGallery] = useState(element.gallery ?? [])
  const [numberClick, setNumberClick] = useState(0)
  const [allGalleryCount, setAllGalleryCount] = useState(0)

  const onChangePosition = async (state: any) => {
    const SIZE = 5
    let galleryList = {
      count: 0,
      results: [
        {
          id: 0,
          innerId: 0,
        },
      ],
    }

    if (state.slidersLength < SIZE) return
    if (allGalleryCount !== 0 && state.slidersLength >= allGalleryCount) return

    setNumberClick(numberClick + 1)

    if (numberClick % SIZE == 0) {
      const page = numberClick / SIZE + 2

      galleryList = await getPortfolioGalleryList({
        portfolioid: state.portfolioid,
        page,
        size: SIZE,
      })
    } else {
      return
    }

    setAllGalleryCount(galleryList.count)

    if (state.slidersLength >= galleryList.count) return

    setGallery([...gallery, ...galleryList.results])
  }

  return (
    <div className={styles.root}>
      <Slider
        sliders={gallery}
        onChangePosition={(state) => onChangePosition({ ...state, portfolioid: element.id })}
      />
      <div className={styles.divider} />
      <div className={styles.inform}>
        <div className={styles.name}>{element.name}</div>
        <div className={styles.time}>Срок: {element.time}</div>
        <div className={styles.price}>Стоимость: {element.price}</div>
      </div>
    </div>
  )
}
