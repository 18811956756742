import { useEffect } from 'react'
import { createGlobalState, useMedia } from 'react-use'

const useGlobalMediaValue = createGlobalState<'wide' | 'medium' | 'smedium' | 'small'>('small')

export const useMediaScreen = () => {
  const [mediaValue, setMediaValue] = useGlobalMediaValue()
  const isWideScreen = useMedia('(min-width: 1240px)', mediaValue !== 'small')
  const isMediumScreen = useMedia('(min-width: 1024px)', mediaValue !== 'small')
  const isSMediumScreen = useMedia('(min-width: 768px)', mediaValue !== 'small')
  const screen = isWideScreen
    ? 'wide'
    : isMediumScreen
    ? 'medium'
    : isSMediumScreen
    ? 'smedium'
    : 'small'

  useEffect(() => {
    setMediaValue(screen)
  }, [setMediaValue, screen])

  return screen
}
