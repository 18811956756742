import { ReactElement, SVGProps } from 'react'
export const SvgMiniLogo = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m23.226 7-7.265 8.414-7.186-8.41H8V27.73a5.2 5.2 0 0 1 .491.269l3.284-2.931V16.14l4.119 4.905h.189l4.142-4.901v7.787H24V7h-.774Z"
      clipRule="evenodd"
    />
  </svg>
)
