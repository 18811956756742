import { ReactElement, SVGProps } from 'react'
export const SvgLaptop = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32" {...props}>
    <g fill="currentColor" clipPath="url(#laptop_svg__a)">
      <path d="M3.503 23.994h24.991c.988 0 1.786-.8 1.786-1.785V4.357a2.379 2.379 0 0 0-2.379-2.38H4.098a2.38 2.38 0 0 0-2.38 2.38V22.21c0 .986.8 1.785 1.785 1.785ZM4.698 4.957H27.3v16.058H4.698V4.957Zm26.707 20.131H.595a.595.595 0 0 0-.595.596v2.205c0 1.18 1.197 2.133 2.673 2.133h26.655c1.476 0 2.672-.956 2.672-2.133v-2.205a.595.595 0 0 0-.595-.596Zm-11.688 3.064h-7.434a.595.595 0 1 1 0-1.192h7.434a.595.595 0 1 1 0 1.192Z" />
    </g>
    <defs>
      <clipPath id="laptop_svg__a">
        <path fill="currentColor" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
)
