import { ReactElement, SVGProps } from 'react'
export const SvgClose = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M1.176 23.999a1.173 1.173 0 0 1-.829-2L22.002.342A1.172 1.172 0 0 1 23.66 2L2.005 23.656a1.17 1.17 0 0 1-.83.343Z"
    />
    <path
      fill="currentColor"
      d="M22.835 23.999a1.168 1.168 0 0 1-.829-.343L.351 2A1.172 1.172 0 1 1 2.008.343l21.655 21.655a1.172 1.172 0 0 1-.828 2Z"
    />
  </svg>
)
