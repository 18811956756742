/* eslint-disable @next/next/no-img-element */

'use client'

import { FC, useState, useReducer } from 'react'
import { useSwipeable } from 'react-swipeable'
import { withStyles } from '@bruitt/classnames'

import { VideoPlayer } from '@/uikit/video-player'
import { Button } from '@/uikit/button'
import { SvgArrow } from '@/uikit/svg'
import { Modal } from '@/uikit/modal'

import styles from './slider.module.scss'

interface SliderProps {
  sliders: any
  onChangePosition?: (data: any) => void
}

const sx = withStyles(styles)

const NEXT = 'NEXT'
const PREV = 'PREV'

type Direction = typeof PREV | typeof NEXT

interface CarouselState {
  pos: number
  sliding: boolean
  dir: Direction
}

type CarouselAction = { type: Direction; numItems: number } | { type: 'stopSliding' }

function reducer(state: CarouselState, action: CarouselAction): CarouselState {
  switch (action.type) {
    case PREV:
      return {
        ...state,
        dir: PREV,
        sliding: true,
        pos: state.pos === 0 ? action.numItems - 1 : state.pos - 1,
      }
    case NEXT:
      return {
        ...state,
        dir: NEXT,
        sliding: true,
        pos: state.pos === action.numItems - 1 ? 0 : state.pos + 1,
      }
    case 'stopSliding':
      return { ...state, sliding: false }
    default:
      return state
  }
}

const getOrder = (index: number, pos: number, numItems: number) => {
  return index - pos < 0 ? numItems - Math.abs(index - pos) : index - pos
}

const getInitialState = (numItems: number): CarouselState => ({
  pos: numItems - 1,
  sliding: false,
  dir: NEXT,
})

const SliderElement = ({ element, size = '' }: any) => (
  <div className={styles.slide}>
    {element.image && (
      <img className={styles.image} src={element.image.replace('.webp', `${size}.webp`)} alt="" />
    )}
    {element.video && (
      <div className={styles.video}>
        <VideoPlayer videoId={element.video} title="" />
      </div>
    )}
  </div>
)

export const Slider: FC<SliderProps> = ({ sliders, onChangePosition }) => {
  const numItems = sliders.length
  const [state, dispatch] = useReducer(reducer, getInitialState(numItems))
  const [isOpen, setIsOpen] = useState(false)
  const slide = (dir: Direction) => {
    dispatch({ type: dir, numItems })
    onChangePosition?.({ ...state, slidersLength: numItems })
    setTimeout(() => {
      dispatch({ type: 'stopSliding' })
    }, 50)
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => slide(NEXT),
    onSwipedRight: () => slide(PREV),
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
  })

  return (
    <>
      <div {...handlers} className={styles.wrapper}>
        <div
          className={sx('container', 'container__full', {
            transition: state.sliding ? 'none' : 'transform',
            transform: !state.sliding
              ? 'next_full'
              : state.dir === PREV
              ? 'prev_full'
              : 'default_full',
          })}
        >
          {sliders.map((item: any, index: number) => (
            <div
              key={item.id}
              className={styles.slot__full}
              style={{ order: getOrder(index, state.pos, numItems) }}
              onClick={() => {
                if (item.image) {
                  setIsOpen(true)
                }
              }}
            >
              <SliderElement element={item} size="_medium" />
            </div>
          ))}
        </div>
      </div>
      <div {...handlers} className={styles.wrapper}>
        <div
          className={sx('container', 'container__preview', {
            transition: state.sliding ? 'none' : 'transform',
            transform: !state.sliding ? 'next' : state.dir === PREV ? 'prev' : 'default',
          })}
        >
          {sliders.map((item: any, index: number) => (
            <div
              key={item.id}
              className={styles.slot__preview}
              style={{
                order: getOrder(index, state.pos, numItems),
                flexBasis:
                  getOrder(index, state.pos, numItems) === 0 ? '25%' : 'calc(25% - 8px * 3 / 4)',
              }}
              onClick={(event) => {
                const sub = sliders.splice(0, index)
                sliders.push(...sub)
                setIsOpen(true)
                event.preventDefault()
                event.stopPropagation()
              }}
            >
              <SliderElement element={item} size="_small" />
            </div>
          ))}
        </div>
        <div className={styles.btn_block}>
          <Button className={sx('arrow', 'arrow__prev')} onClick={() => slide(PREV)}>
            <SvgArrow />
          </Button>
          <Button className={sx('arrow', 'arrow__next')} onClick={() => slide(NEXT)}>
            <SvgArrow />
          </Button>
        </div>
      </div>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <div {...handlers} className={styles.wrapper}>
          <div
            className={sx('container', 'container__modal', {
              transition: state.sliding ? 'none' : 'transform',
              transform: !state.sliding
                ? 'next_full'
                : state.dir === PREV
                ? 'prev_full'
                : 'default_full',
            })}
          >
            {sliders.map((item: any, index: number) => (
              <div
                key={item.id}
                className={styles.slot__modal}
                style={{ order: getOrder(index, state.pos, numItems) }}
              >
                <SliderElement element={item} size="_perfect" />
              </div>
            ))}
          </div>
        </div>
        <div className={styles.btn_block}>
          <Button className={sx('arrow', 'arrow__prev')} onClick={() => slide(PREV)}>
            <SvgArrow />
          </Button>
          <Button className={sx('arrow', 'arrow__next')} onClick={() => slide(NEXT)}>
            <SvgArrow />
          </Button>
        </div>
      </Modal>
    </>
  )
}
