import { ReactElement, SVGProps } from 'react'
export const SvgMap = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 1.333a5.333 5.333 0 0 0-5.333 5.334c0 2.065 1.343 4.07 2.817 5.624A19.62 19.62 0 0 0 8 14.515a19.627 19.627 0 0 0 2.516-2.224c1.473-1.555 2.817-3.559 2.817-5.624A5.334 5.334 0 0 0 8 1.333Zm0 14-.37.555-.002-.002-.005-.002-.015-.01a7.106 7.106 0 0 1-.256-.181 20.934 20.934 0 0 1-2.836-2.484c-1.527-1.612-3.183-3.941-3.183-6.542a6.667 6.667 0 0 1 13.334 0c0 2.601-1.657 4.93-3.183 6.542a20.935 20.935 0 0 1-3.037 2.626l-.055.038-.016.01-.004.004h-.001s-.001.001-.371-.554Zm0 0 .37.555a.667.667 0 0 1-.74 0l.37-.555Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 5.333A1.333 1.333 0 1 0 8 8a1.333 1.333 0 0 0 0-2.667ZM5.333 6.667a2.667 2.667 0 1 1 5.334 0 2.667 2.667 0 0 1-5.334 0Z"
      clipRule="evenodd"
    />
  </svg>
)
