'use client'

import { FC, useState } from 'react'
import Image from 'next/image'
import { withStyles } from '@bruitt/classnames'

import { Content } from '@/api'
import { Container } from '@/uikit/container'
import { Button } from '@/uikit/button'

import styles from './how-we-work.module.scss'

interface HowWeWorkProps {
  content?: Content
}

const sx = withStyles(styles)

export const HowWeWork: FC<HowWeWorkProps> = ({ content }) => {
  const [transform, setTransform] = useState<'next' | 'prev' | undefined>(undefined)

  if (!content) return null

  const { header, cards } = content

  return (
    <div className={styles.root}>
      <Container>
        <div className={styles.wrapper}>
          <h2>{header}</h2>
          <div>
            <div className={sx('list', { disabled: transform ? transform : 'prev' })}>
              <div
                className={sx('container', {
                  transform,
                })}
              >
                {!!cards?.length &&
                  cards.map(({ id, iconImg, header, text }) => (
                    <div key={id} className={styles.item}>
                      <div className={styles.icon}>
                        {iconImg && (
                          <Image
                            src={iconImg}
                            alt={header || 'Иконки преимуществ'}
                            fill
                            object-fit="fill"
                            sizes="(max-width: 768px) 48px, 32px"
                          />
                        )}
                      </div>
                      <div className={styles.text_block}>
                        <div className={styles.title}>{header}</div>
                        <div className={styles.text}>{text}</div>
                      </div>
                    </div>
                  ))}
              </div>
              <div className={styles.btn_block}>
                <Button
                  variant="green-link"
                  disabled={transform ? transform === 'prev' : true}
                  onClick={() => setTransform('prev')}
                >
                  <div className={styles.btn_prev} />
                </Button>
                <Button
                  variant="green-link"
                  disabled={transform === 'next'}
                  onClick={() => setTransform('next')}
                >
                  <div className={styles.btn_next} />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}
