import {
  PaginatedPortfolioWorkListPublic,
  PaginatedPortfolioTagListPublic,
  PortfolioListRequest,
  PortfolioPortfolioGalleryListRequest,
  PaginatedPortfolioWorkGalleryListPublic,
} from '@/api'
import { portfolioApi } from './portfolio'
import { handleApiError } from '@/lib/api'
import { removeUndefined } from '@/lib/object'
import { REVALIDATE } from '@/constants/constants'

export const getPortfolioList = async (
  requestParameters: PortfolioListRequest,
): Promise<PaginatedPortfolioWorkListPublic> => {
  const params = REVALIDATE
  const response = await portfolioApi
    .portfolioList({ ...requestParameters }, params)
    .catch(handleApiError)

  if (response === undefined) {
    throw `"response" is undefined for request "portfolioList()"`
  }

  if (response.body == null) {
    throw `"response.body" is ${response.body} for request "portfolioList()"`
  }

  return removeUndefined(response.body)
}

export const getPortfolioTagList = async (): Promise<PaginatedPortfolioTagListPublic> => {
  const params = REVALIDATE
  const response = await portfolioApi.portfolioTagList({}, params).catch(handleApiError)

  if (response === undefined) {
    throw `"response" is undefined for request "portfolioTagList()"`
  }

  if (response.body == null) {
    throw `"response.body" is ${response.body} for request "portfolioTagList()"`
  }

  return removeUndefined(response.body)
}

export const getPortfolioGalleryList = async (
  requestParameters: PortfolioPortfolioGalleryListRequest,
): Promise<PaginatedPortfolioWorkGalleryListPublic> => {
  const params = REVALIDATE
  const response = await portfolioApi
    .portfolioPortfolioGalleryList(requestParameters, params)
    .catch(handleApiError)

  if (response === undefined) {
    throw `"response" is undefined for request "portfolioPortfolioGalleryList()"`
  }

  if (response.body == null) {
    throw `"response.body" is ${response.body} for request "portfolioPortfolioGalleryList()"`
  }

  return removeUndefined(response.body)
}
