import { ReactElement, SVGProps } from 'react'
export const SvgArrow = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 32" {...props}>
    <g clipPath="url(#arrow_svg__a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M2 15.958c0-.296.119-.58.33-.789a1.13 1.13 0 0 1 .795-.327h39.391l-7.08-7.02a1.113 1.113 0 0 1 0-1.579 1.131 1.131 0 0 1 1.593 0l9 8.925a1.113 1.113 0 0 1 .244 1.218c-.056.135-.14.258-.244.362l-9 8.925a1.131 1.131 0 0 1-1.593 0 1.112 1.112 0 0 1 0-1.58l7.08-7.02H3.125a1.13 1.13 0 0 1-.796-.326A1.11 1.11 0 0 1 2 15.958Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="arrow_svg__a">
        <path fill="currentColor" d="M0 32V0h48v32z" />
      </clipPath>
    </defs>
  </svg>
)
