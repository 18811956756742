import { withStyles } from '@bruitt/classnames'
import { FC, ReactNode, useState } from 'react'

import { Content } from '@/api'
import { formatCurrency } from '@/lib/number'
import { Container } from '@/uikit/container'
import { Button } from '@/uikit/button'
import { Modal } from '@/uikit/modal'
import { SimpleServiceRequest } from '@/components/simple-service-request'

import styles from './banner.module.scss'

const sx = withStyles(styles)

interface BannerProps {
  content: Content
  children: ReactNode
  service: {
    measureUnit?: string | null
    price?: number | null
    header?: string
    name?: string | null
    slug?: string | null
    id?: number | null
  }
  template: 1 | 2
  pageType?: string
}

export const Banner: FC<BannerProps> = ({ children, content, service, template, pageType }) => {
  const [isOpen, setIsOpen] = useState(false)

  if (!content) return null

  const { otherData, cards } = content

  if (!otherData) return null

  const { subtitle, text, comment } = otherData

  return (
    <Container>
      <div className={sx('root', { template, pageType })}>
        <div className={styles.content}>
          <h1 className={styles.header}>{service.header}</h1>
          <div className={styles.subtitle}>
            {subtitle}
            {service.price && (
              <span className={styles.price}>
                {formatCurrency(service.price)} ₽/
                {service.measureUnit}
              </span>
            )}
          </div>
          {text && <div className={styles.text} dangerouslySetInnerHTML={{ __html: text }} />}
          <div className={styles.list}>
            {cards &&
              cards.map(({ header, text }) => (
                <div key={text} className={styles.list_item}>
                  {text && (
                    <div className={sx('item_round', { isCLip: text.length > 3 })}>
                      <div className={styles.item_number}>{text}</div>
                    </div>
                  )}
                  <div className={styles.item_text}>{header}</div>
                </div>
              ))}
          </div>
          <div className={styles.button}>
            <Button onClick={() => setIsOpen(true)}>Вызвать замерщика</Button>
          </div>
          <div className={styles.desc}>{comment}</div>
        </div>
        <div className={styles.form}>{children}</div>
      </div>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <SimpleServiceRequest
          position="bottom"
          header="Отправить заявку на консультацию"
          description="<p>Предоставим полную смету на весь ремонт в день выезда замерщика.</p><br /><p>Смета включает стоимость выполнения всех работ и перечень всех необходимых материалов.</p>"
          btnText="Рассчитать стоимость"
          variant="modal"
          service={service}
          reachGoalName="banner"
        />
      </Modal>
    </Container>
  )
}
