'use client'

import { withStyles } from '@bruitt/classnames'
import { FC, useEffect, useRef, useState } from 'react'

import { useMediaScreen } from '@/lib/use-media-screen'

import styles from './collapse.module.scss'

export interface CollapseProps {
  title: string
  description: string
  onClick?: () => void
}

const sx = withStyles(styles)

export const Collapse: FC<CollapseProps> = ({ title, description, onClick }) => {
  const [isOpenCollapse, setIsOpenCollapse] = useState(false)
  const [maxHeight, setMaxHeight] = useState<string | number>('100%')
  const refDescription = useRef<HTMLDivElement>(null)

  const mediaScreen = useMediaScreen()

  const handleClickCollapse = () => {
    onClick?.()
    setIsOpenCollapse((isOpenCollapse) => !isOpenCollapse)
  }

  useEffect(() => {
    setMaxHeight(refDescription.current?.scrollHeight ?? 0)
  }, [mediaScreen])

  return (
    <div
      className={sx(styles.root, {
        root___open: isOpenCollapse,
      })}
      onClick={handleClickCollapse}
    >
      <div className={styles.header}>
        <div className={styles.title} itemProp="name">
          {title}
        </div>
        <div className={styles.icon} />
      </div>
      <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
        <div
          itemProp="text"
          ref={refDescription}
          style={{ maxHeight: isOpenCollapse ? maxHeight : 0 }}
          dangerouslySetInnerHTML={{ __html: description }}
          className={styles.description}
        />
      </div>
    </div>
  )
}
