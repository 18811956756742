import { ReactElement, SVGProps } from 'react'
export const SvgPhone = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" {...props}>
    <g clipPath="url(#phone_svg__a)">
      <path
        fill="currentColor"
        d="M13.26 15.287c-.06 0-.12 0-.187-.007a13.922 13.922 0 0 1-6.053-2.153 13.72 13.72 0 0 1-4.2-4.2A13.912 13.912 0 0 1 .673 2.86a1.98 1.98 0 0 1 .513-1.54 2.026 2.026 0 0 1 1.48-.653h2c.467 0 .947.167 1.32.487.367.313.607.753.674 1.233.08.593.227 1.18.433 1.733.134.36.167.747.08 1.12-.08.374-.266.72-.533.987l-.48.48a10.074 10.074 0 0 0 3.06 3.06l.48-.48c.273-.273.62-.46.993-.533a2 2 0 0 1 1.12.08 8.09 8.09 0 0 0 1.727.433 1.999 1.999 0 0 1 1.727 2.02v1.993a1.987 1.987 0 0 1-.647 1.48 1.978 1.978 0 0 1-.713.42 1.962 1.962 0 0 1-.647.107ZM4.68 2H2.667c-.094 0-.18.02-.267.06a.594.594 0 0 0-.227.154.545.545 0 0 0-.14.24.752.752 0 0 0-.033.273 12.66 12.66 0 0 0 1.94 5.48 12.449 12.449 0 0 0 3.8 3.8 12.61 12.61 0 0 0 5.467 1.947.717.717 0 0 0 .26-.034.73.73 0 0 0 .24-.14.543.543 0 0 0 .16-.226.646.646 0 0 0 .053-.274v-2.013a.647.647 0 0 0-.16-.447.644.644 0 0 0-.413-.226 9.27 9.27 0 0 1-2.014-.5.652.652 0 0 0-.373-.027.657.657 0 0 0-.333.18l-.84.847a.666.666 0 0 1-.8.106 11.405 11.405 0 0 1-4.254-4.246.703.703 0 0 1 .114-.82l.846-.847a.649.649 0 0 0 .174-.327.648.648 0 0 0-.027-.373 9.247 9.247 0 0 1-.507-2.02.667.667 0 0 0-.22-.407A.68.68 0 0 0 4.68 2Z"
      />
    </g>
    <defs>
      <clipPath id="phone_svg__a">
        <path fill="currentColor" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)
