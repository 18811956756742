import { ReactElement, SVGProps } from 'react'
export const SvgNextArrow = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.527 2.861c.26-.26.683-.26.943 0l4.642 4.642a.667.667 0 0 1 0 .943L6.47 13.088a.667.667 0 1 1-.943-.942l4.171-4.171-4.17-4.17a.667.667 0 0 1 0-.944Z"
      clipRule="evenodd"
    />
  </svg>
)
