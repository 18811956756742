import { withStyles } from '@bruitt/classnames'
import { ComponentProps, forwardRef } from 'react'

import { ButtonBase } from '@/uikit/button-base'

import styles from './button-tab.module.scss'

export type ButtonTabProps = ComponentProps<typeof ButtonBase> & {
  fullWidth?: boolean
  isActive?: boolean
}

const sx = withStyles(styles)

export const ButtonTab = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonTabProps>(
  ({ fullWidth = false, isActive, children, className, ...props }, ref) => (
    <ButtonBase className={sx('root', className, { fullWidth, isActive })} ref={ref} {...props}>
      {children}
    </ButtonBase>
  ),
)

ButtonTab.displayName = 'ButtonTab'
